import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { useFormik } from 'formik';

import AppInput from 'shared/AppInput';
import AppToast from 'shared/AppToast';
import SinpeForm from 'components/views/orders/components/SinpeForm';
import GatewayLayout from './components/GatewayLayout';
import InstructionLine from './components/InstructionLine';

import axios from 'utils/axios-rails';

import { GatewayRegionAccountProvider } from '@hooks/GatewayRegionAccountContext';

const propTypes = {
  submitURL: PropTypes.string.isRequired,
  checkoutId: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  cancelURL: PropTypes.string.isRequired,

  displayAmount: PropTypes.string,
  regionAccountCountry: PropTypes.oneOf(['costa_rica', 'guatemala']).isRequired,
}

const defaultProps = {

}

const SinpeGatewayForm = (props) => {
  const layoutRef = useRef(null);
  console.log("PROP", props);

  const handleSubmit = async (values) => {
    try {
      const response = await axios.post(props.submitURL, {
        sinpe: {
          national_id: values.id_type == "ordinary" ? `0${values.national_id}` : values.national_id , // On CR it is WIDELY common for people with Cedula Física to omit writing the 0 at the beginning, so it that's the case AUTO add it there, for other kinds ID should be send as written
          amount: props.amount,
          checkout_id: props.checkoutId,
        }
      });
      const { data } = response;
      if(data.success){
        layoutRef.current.setToastMessage(data.message);
        window.location.href = data.redirect_url; // Redirect to shopify with response params
      }
      else{
        formik.setErrors(data.errors);
        layoutRef.current.setToastMessage(data.message);
      }
    } catch (error) {
      console.log("ErrorC", error)
      layoutRef.current.setToastMessage("Ocurrio un error de conexión con el servicio.");
    }
  }

  const formik = useFormik({ 
    initialValues: { national_id: "", id_type: "ordinary" },
    onSubmit: handleSubmit,
  });

  const displayAmount = props.displayAmount || `₡${props.amount}`;

  return (
    <GatewayRegionAccountProvider regionAccountCountry={props.regionAccountCountry}>
      <GatewayLayout ref={ layoutRef } 
        loading={ formik.isSubmitting } 
        cancelURL={ props.cancelURL } onSubmit={formik.handleSubmit}
        totalAmount={ displayAmount }
      >
        {/* Instructions */}
        <div className="mb32">
          <InstructionLine  number={1}
            content={ <p className="mb0">Realizá tu pago de <strong>{ displayAmount }</strong> via SINPE Móvil al <strong>8382-1515</strong>. El monto debe ser exacto.</p> }
          />
          <InstructionLine  number={2}
            content={ <p className="mb0">Una vez que hayás realizado el pago ingresá tu cédula abajo.</p> }
          />
          <InstructionLine  number={3}
            content={ <p className="mb0">Da click en <strong>"Completar Orden"</strong> y listo. Automáticamente buscaremos tu pago en el banco y lo asociaremos a tu orden.</p> }
          />
        </div>

        <SinpeForm
          values={ formik.values }
          errors={ formik.errors }
          handleChange={ formik.handleChange }
          setFieldValue={ formik.setFieldValue }
        />
      </GatewayLayout>
    </GatewayRegionAccountProvider>
  )
};



SinpeGatewayForm.propTypes = propTypes;
SinpeGatewayForm.defaultProps = defaultProps;

export default SinpeGatewayForm;