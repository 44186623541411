import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useFormik } from 'formik';
import useActionCable from '@hooks/useActionCable';
import useChannel from '@hooks/useChannel';

import Badge from 'react-bootstrap/Badge';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import AppInput from 'shared/AppInput';
import AppToast from 'shared/AppToast';
import SinpeForm from 'components/views/orders/components/SinpeForm';
import GatewayLayout from './components/GatewayLayout';
import InstructionLine from './components/InstructionLine';

import ZunifyLogo from 'images/gateway/zunify-logo.png';

import axios from 'utils/axios-rails';

import { GatewayRegionAccountProvider } from '@hooks/GatewayRegionAccountContext';

const propTypes = {
  submitURL: PropTypes.string.isRequired,
  fetchStatusURL: PropTypes.string.isRequired,

  checkoutId: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  displayAmount: PropTypes.string,
  cancelURL: PropTypes.string.isRequired, 
  chargeCode: PropTypes.string, // If present skips create charge code step

  actionCableURL: PropTypes.string.isRequired,
  regionAccountCountry: PropTypes.oneOf(['costa_rica', 'guatemala']).isRequired,
}

const defaultProps = {
  chargeCode: null,
}

const SinpeGatewayForm = (props) => {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [chargeCode, setChargeCode] = useState(props.chargeCode);

  const { actionCable } = useActionCable(props.actionCableURL);
  const {subscribe, unsubscribe, send} = useChannel(actionCable)

  const layoutRef = useRef(null);
  // console.log("PROP", props);

  // Create charge and begin pulling data
  const handleSubmit = async (values) => {
    try {
      const response = await axios.post(props.submitURL, {
        amount: props.amount,
        phone: values.phone,
        pin: values.pin,
        checkout_id: props.checkoutId,
      });
      const { data } = response;
      if(data.success){
        setChargeCode(data.charge_code);
        // layoutRef.current.setToastMessage("");
      }
      else{
        console.warn("Error", data)
        layoutRef.current.setToastMessage(data.error);
      }
    } catch (error) {
      console.error(error)
      layoutRef.current.setToastMessage("Ocurrio un error de conexión con el servicio."); // Maybe redirect to x_url_cancel
    }
  }

  const fetchChargeStatus = async () => {
    if(!!chargeCode && !fetchLoading){
      setFetchLoading(true);
      try {
        const response = await axios.post(props.fetchStatusURL, { charge_code: chargeCode });
        const { data } = response;
        if(!!data.redirect_url){
          layoutRef.current.setToastMessage(data.message);
          window.location.href = data.redirect_url; // Redirect to shopify with response params
        }
        else if(data.invalid_charge){ // expired or cancelled
          setChargeCode(null); // close modal
          layoutRef.current.setToastMessage("Pago ha vencido, por favor ingrese las credenciales nuevamente.");
        }
        else{
          layoutRef.current.setToastMessage("Esperando confirmación...");
        }
      } catch (error) {
        layoutRef.current.setToastMessage("Ocurrio un error de conexión con el servicio."); // Maybe redirect to x_url_cancel
      }
      setFetchLoading(false);
    }
  };

  const formik = useFormik({ 
    initialValues: { phone: "", pin: "" },
    onSubmit: handleSubmit,
  });

  const displayAmount = props.displayAmount || `₡${props.crossParams.amount}`;

  const { values, errors } = formik;

  // Main fetching logic by events
  useEffect(() => {
    if(!!chargeCode){
      subscribe({ channel: 'ZunifyChannel', chargeCode: chargeCode }, { // subscribe to specific channel
        received: fetchChargeStatus // inmediatly fetch status when event comes (which means status is paid!)
      })
    }
    return () => {
      unsubscribe()
    }
  }, [chargeCode])

  // Long pulling fallback
  useEffect(() => {
    const timer = setInterval(fetchChargeStatus, 10000); // each 10secs (for matters of test mode shopify)
    return () => clearInterval(timer);
  }, [chargeCode])


  return (
    <GatewayRegionAccountProvider regionAccountCountry={props.regionAccountCountry}>
      <GatewayLayout ref={ layoutRef } 
        loading={ formik.isSubmitting } 
        cancelURL={ props.cancelURL } onSubmit={formik.handleSubmit}
        totalAmount={ displayAmount }
        submitText="COMPLETAR ORDEN"
      >

        <div className="mb32">
          <InstructionLine  number={1}
            content={ <p className="mb0">Ingresá tu teléfono y PIN Zunify y haz click en <strong>"Completar Orden"</strong>.</p> }
          />
          <InstructionLine  number={2}
            content={ <p className="mb0">Desde Zunify abrí la solicitud de cobro por <strong>{ displayAmount }</strong> en la sección de notificaciones.</p> }
          />
          <InstructionLine  number={3}
            content={ <p className="mb0">Realizá tu pago desde Zunify y listo. La orden se completará automáticamente.</p> }
          />
        </div>

        <AppInput 
          label="Teléfono vinculado a Zunify" name="phone" 
          autoComplete="off"
          labelClassName="font-weight-bold"
          value={ values.phone } onChange={ formik.handleChange }
          placeholder="88888888" error={ errors && errors.phone }
        />

        <div className="mt12">
          <AppInput
            label="PIN" name="pin"
            type="password"
            autoComplete={"off"}
            labelClassName="font-weight-bold"
            value={ values.pin } onChange={ formik.handleChange }
            placeholder="1111" error={ errors && errors.pin }
          />
        </div>

        <Modal show={!!chargeCode} size="lg" centered onHide={null}>
          <Modal.Body style={{ paddingTop: "3rem", paddingBottom: "3rem" }}>
            <div className='flex flex-column align-items-center text-center'>
              <img src={ ZunifyLogo } height="48px" />

              <h5 className='mv16'>
                Confirmá tu transacción en Zunify y no cerrés esta ventana hasta que la orden se complete automáticamente
              </h5>

              <Spinner animation="border" />
              <p className='fs12 mb0 mt8' style={{ color: "gray" }}>Esperando tu confirmación</p>
            </div>
          </Modal.Body>
        </Modal>

      </GatewayLayout>
    </GatewayRegionAccountProvider>
  )
};

SinpeGatewayForm.propTypes = propTypes;
SinpeGatewayForm.defaultProps = defaultProps;

export default SinpeGatewayForm;