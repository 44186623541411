import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import AppInput from 'shared/AppInput';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import CardForm from './CardForm';
import MuiInput from './MuiInput';

import CredixLogo from 'images/gateway/credix-checkout-grande.png';
import DaviviendaLogo from 'images/gateway/davivienda-checkout.png';

import *  as shapeProps from 'utils/shape-props';

import { ACCORDION_OPTIONS } from '../CuotasGatewayForm';

import { useGatewayRegionAccount } from '@hooks/GatewayRegionAccountContext';

const propTypes = {
  ...shapeProps.formikProps,
  resetForm: PropTypes.func.isRequired,

  // options
  credomaticInstallments: PropTypes.array.isRequired,
  credixInstallments: PropTypes.array.isRequired,

  // Accordion state
  activeAccordion: PropTypes.string,
  setActiveAccordion: PropTypes.func.isRequired,

  // Credix pin
  credixPinEnabled: PropTypes.bool,
  setCredixPinEnabled: PropTypes.func.isRequired,
}

const defaultProps = {

}

const CredomaticForm = (props) => {
  const regionData = useGatewayRegionAccount();
  const { activeAccordion, setActiveAccordion, credixPinEnabled, setCredixPinEnabled } = props;
  

  const credixPinToggle = () => {
    const newValue = !credixPinEnabled;
    props.setFieldValue("credix_pin", ""); // Reset pin value each time the user toggles the pin form
    setCredixPinEnabled(newValue);
  }

  // When changing the active accordion, reset the form and set the first installment as default
  useEffect(() => {
    props.resetForm();
    if (activeAccordion == ACCORDION_OPTIONS.credomatic) {
      // console.log("WHY", props.credomaticInstallment)
      props.setFieldValue("credomatic_payment_id", props.credomaticInstallments[0].value);
    }
    else if (activeAccordion == ACCORDION_OPTIONS.credix) {
      props.setFieldValue("credix_payment_id", props.credixInstallments[0].value);
    } else {
      props.setFieldValue("davivienda_payment_id", props.daviviendaInstallments[0].value);
    }

  }, [activeAccordion]);

  // TODO Optimize all accordion active conditions

  return (
    <>
      <h2 className="fs18 mb12">
        <strong>
          { regionData.cuotasFormTitle }
        </strong>
      </h2>
      <Accordion activeKey={activeAccordion} onSelect={setActiveAccordion} className='credomatic-accordion'>
        {/* Credomatic */}
        <Card>
          <Accordion.Toggle as={Card.Header} className={`flex justify-between ${activeAccordion == ACCORDION_OPTIONS.credomatic ? "open" : ""}`} eventKey={ACCORDION_OPTIONS.credomatic}>
            <LegacyRadioHeader title={ regionData.cuotasCredomaticTitle } activeKey={activeAccordion} eventKey={ACCORDION_OPTIONS.credomatic} />
            <span className='cards-area'>
              { regionData.cuotasCredomaticLogoComponent }
            </span>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="credomatic">
            <Card.Body>
              {/* Credomatic installments select */}
              <Form.Group className='mui-input filled'>
                <label>Cuotas</label>
                <Form.Control name="credomatic_payment_id" as="select" custom onChange={props.handleChange} value={props.values.credomatic_payment_id}>
                  {props.credomaticInstallments.map(item => (
                    <option value={item.value} key={item.value}>{item.label}</option>
                  ))}
                </Form.Control>
              </Form.Group>

              <CardForm
                label="Nombre del titular"
                values={props.values}
                handleChange={props.setFieldValue}
              />
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        {/* CREDIX */}
        { !regionData.disableCredix &&
          <Card>
            <Accordion.Toggle as={Card.Header} className={`flex justify-between ${activeAccordion == ACCORDION_OPTIONS.credix ? "open" : ""}`} eventKey={ACCORDION_OPTIONS.credix}>
              <LegacyRadioHeader title="Credix" activeKey={activeAccordion} eventKey={ACCORDION_OPTIONS.credix} />
              <span className='cards-area'>
                <img className="credix-logo" src={CredixLogo} alt='Credix' />
              </span>
            </Accordion.Toggle>

            <Accordion.Collapse eventKey={ACCORDION_OPTIONS.credix}>
              <Card.Body>
                {/* Credix installments select */}
                <Form.Group className='mui-input filled'>
                  <label>{`Cuotas ${props.values.credix_payment_id}`}</label> {/* Cuotas 3, Cuotas 12, etc. */}
                  <Form.Control name="credix_payment_id" as="select" custom onChange={props.handleChange} value={props.values.credix_payment_id}>
                    {props.credixInstallments.map(item => (
                      <option value={item.value} key={item.value}>{item.label}</option>
                    ))}
                  </Form.Control>
                </Form.Group>

                {credixPinEnabled ? (
                  <MuiInput
                    name="credix_pin"
                    value={props.values.credix_pin}
                    label="PIN"
                    type="tel"
                    onChange={props.handleChange}
                  />
                ) : (
                  <CardForm
                    label="Nombre del titular"
                    values={props.values}
                    handleChange={props.setFieldValue}
                  />
                )}

                <Button className="fs14 black-link p0" variant="link" onClick={credixPinToggle}>
                  {credixPinEnabled ? "Pagar con Tarjeta" : "Pagar con Pin Credix"}
                </Button>

              </Card.Body>
            </Accordion.Collapse>
          </Card>
        }

        {/* Davivienda */}
        { !regionData.disableDavivienda && (
          <Card>
            <Accordion.Toggle as={Card.Header} className={`flex justify-between ${activeAccordion == ACCORDION_OPTIONS.davivienda ? "open" : ""}`} eventKey={ACCORDION_OPTIONS.davivienda}>
              <LegacyRadioHeader title="Paguitos Davivienda" activeKey={activeAccordion} eventKey={ACCORDION_OPTIONS.davivienda} />
              <span className='cards-area'>
                <img className="davivienda-logo" src={DaviviendaLogo} alt='Davivienda' />
              </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={ACCORDION_OPTIONS.davivienda}>
              <Card.Body>
                {/* Davivienda installments select */}
                <Form.Group className='mui-input filled'>
                  <label>{`Cuotas ${props.values.davivienda_payment_id}`}</label> {/* Cuotas 3, Cuotas 18, etc. */}
                  <Form.Control name="davivienda_payment_id" as="select" custom onChange={props.handleChange} value={props.values.davivienda_payment_id}>
                    {props.daviviendaInstallments.map(item => (
                      <option value={item.value} key={item.value}>{item.label}</option>
                    ))}
                  </Form.Control>
                  {!!props.values.disable &&
                    <div className='mt8'>
                      <span className='fs14'>
                        Plazos de <strong>18 y 24 meses</strong> exclusivos para tarjetas <strong>Davivienda Unimart</strong>. <a className='black' target='_blank' href="https://www.unimart.com/pages/aplicacion-tarjeta-davivienda-unimart"><u>Aplica aquí</u></a>
                      </span>
                    </div>
                  }
                </Form.Group>

                <CardForm
                  label="Nombre del titular"
                  values={props.values}
                  handleChange={props.setFieldValue}
                />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        )}
      </Accordion>
    </>
  )
};

const LegacyRadioHeader = ({ activeKey, eventKey, title }) => (
  <div className='flex align-items-center'>
    <label className={`accordion-radio-placeholder${eventKey == activeKey ? " active" : ""}`}><span></span></label>
    <h5 className='mb0 ml4 fs14'>{title}</h5>
  </div>
)

CredomaticForm.propTypes = propTypes;
CredomaticForm.defaultProps = defaultProps;

export default CredomaticForm;