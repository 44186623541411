import React, { useContext, createContext } from "react";

import HeaderUnimartCR from 'images/gateway/costa_rica/gateway-unimart-header.png';
import HeaderUnimartGT from 'images/gateway/guatemala/gateway-unimart-header.png';

// CR credomatic logo
import TasaCeroLogo from 'images/gateway/tasa-0.png';
import MiniCuotasLogo from 'images/gateway/mini-cuotas.png';
import CuotasBadges from 'images/gateway/paybadgesbarulu.jpg'

// GT credomatic logo
import BacLogoGT from 'images/gateway/guatemala/BAC_LOGO.png';

// Context
const GatewayRegionAccountContext = createContext(null);

// Provider
/**
 * Based on provided RegionAccount.country, return data related to the region specifically in terms of assets, texts, etc.
 * 
 * @param {'costa_rica' | 'guatemala'} regionAccountCountry
 * @param {React.ReactNode} children
 */
export const GatewayRegionAccountProvider = ({ children, regionAccountCountry }) => {
  const regionData = REGIONS_DATA[regionAccountCountry];
  if(!regionData) {
    throw new Error(`RegionAccount country ${regionAccountCountry} not found in REGIONS_DATA`);
  }

  return (
    <GatewayRegionAccountContext.Provider value={ regionData }>
      { children }
    </GatewayRegionAccountContext.Provider>
  );
};

// Hook
/**
 * 
 * @returns {{
 *  header: string, 
 *  footerLogo: string,
 *  cuotasFormTitle: string,
 *  cuotasCredomaticTitle: string,
 *  cuotasCredomaticLogoComponent: React.ReactNode,
 *  disableCredix: boolean,
 *  disableDavivienda: boolean
 *  cuotasFooterLogo?: string
 * }} - The return object.
 */
export const useGatewayRegionAccount = () => {
  const context = useContext(GatewayRegionAccountContext);
  if (context === undefined) {
    console.error('useGatewayRegionAccount must be used within a GatewayRegionAccountProvider');
    throw new Error('useGatewayRegionAccount must be used within a GatewayRegionAccountProvider');
  }
  
  return context;
};

const REGIONS_DATA = {
  'costa_rica': {
    header: HeaderUnimartCR,
    cuotasFormTitle: '¿COMO DESEA PAGAR?',
    cuotasCredomaticTitle: 'Cuotas Credomatic',
    cuotasCredomaticLogoComponent: (
      <>
        <img className="credomatic-logo" src={TasaCeroLogo} alt='Tasa cero' />
        <img className="credomatic-logo" src={MiniCuotasLogo} alt='Mini cuotas' />
      </>
    ),
    cuotasFooterLogo: CuotasBadges,
  },
  'guatemala': {
    header: HeaderUnimartGT,
    cuotasFormTitle: 'Seleccionar Plazo',
    cuotasCredomaticTitle: 'Cuotas BAC',
    cuotasCredomaticLogoComponent: (
      <img className="credomatic-logo" src={BacLogoGT} alt='BAC' />
    ),
    disableCredix: true,
    disableDavivienda: true
  }
}


export default useGatewayRegionAccount;
