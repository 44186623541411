import React, { useState, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import AppToast from 'shared/AppToast';

import ChevronLeft from 'images/icons/material-icon-chevron-left.png';

import { useGatewayRegionAccount } from '@hooks/GatewayRegionAccountContext';

const propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool, // currently disabled and loading has the same effect (disabled) but should be change into two different UI states

  cancelURL: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,

  totalAmount: PropTypes.string.isRequired,
  
  loadingText: PropTypes.string,
  submitText: PropTypes.string,
  displayCuotasBadges: PropTypes.bool,
}

const defaultProps = {
  loading: false,
  disabled: false,
  displayCuotasBadges: false,

  loadingText: "PROCESANDO...",
  submitText: "COMPLETAR ORDEN",
}

/**
 * @type React.ForwardRefRenderFunction<React.FunctionComponent, propTypes>
 */
const GatewayLayout = forwardRef( (props, ref) => {
  const regionData = useGatewayRegionAccount();
  const [toastMessage, setToastMessage] = useState(null);
  const closeToast = () => setToastMessage(null);

  // List of functions to expose on parent
  useImperativeHandle(ref, () => ({
    setToastMessage: setToastMessage,
    closeToast: closeToast,
  }));

  return (
    <div className="gateway-container">
      <div className="gateway-wrap">
        <div className="gateway-main">
          <Form className='form-layout' style={{ position: "relative" }} onSubmit={ props.onSubmit } >
      
            <AppToast
              title="Información"
              message={ toastMessage }
              handleClose={ closeToast }
            />

            <div className="mb28">
              <img src={ regionData.header } width="100%" />
            </div>

            {/* Specific Gateway Content */}
            { props.children }
            {/* ======================== */}

            <div className="flex justify-end mt12">
              <h5 style={{ fontSize: 18 }}>
                <span style={{ fontSize: "0.8em", color: "#4b4b4b" }}>Total: </span> { props.totalAmount }
              </h5>
            </div>
  
            {/* SUBMIT Button */}
            <Row>
              <Col sm={12}>
                <div className="gateway-submit-section">
                  <Button className="submit-button mb20 fs14 border-radius-2 full-width-mobile" variant="dark" type="submit" disabled={ props.loading || props.disabled }>
                    { props.loading ? props.loadingText : props.submitText }
                  </Button>
                  <a href={ props.cancelURL || "https://unimart.com/cart" } style={{ fontSize: 12 }} className="submit-back-link primary fs14 no-underline">
                    <img height={20} src={ChevronLeft} /> 
                    <span>Volver a opciones de pago</span>
                  </a>
                </div>
              </Col>
              { props.displayCuotasBadges &&
                <Col sm={12}>
                  <div className='pv12 flex flex-column align-items-end'>
                    <div className="text-subtitle">Transacción segura con protocolo SSL de 128 bits.</div>
                    { !!regionData.cuotasFooterLogo &&
                      <img height="22.5" src={ regionData.cuotasFooterLogo } />
                    }
                  </div>
                </Col>
              }
            </Row>
          </Form>
        </div>
        <div className="gateway-sidebar">
        </div>
      </div>
    </div>
  )
});

GatewayLayout.propTypes = propTypes;
GatewayLayout.defaultProps = defaultProps;


export default GatewayLayout;